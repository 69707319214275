var jQuery;

//var UIkit;

var setInterval, clearInterval;

var screen;

( function($) {
  $( document ).ready( function() {
    var docEl = document.documentElement;
    var width = window.innerWidth ? window.innerWidth : docEl.clientWidth ? docEl.clientWidth : screen.width;
    
    // Remove any line breaks in the media slider shortcode 
    $( '.flannel-media-slider-container' ).find( 'br' ).remove( 'br' );
    
    
    // Remove any line paragraph tags in the accordion shortcode 
    $( '.flannel-accordion-container' ).children( 'p' ).remove( 'p' );
    
    
    // Update and display current and total number of franchise images list items
    var TotalFranchiseImages  = $( "#franchiseRowImages li" ).length;
    var CurrentFranchiseImage = 1;
    
    $( '#franchiseRowTotal' ).text( TotalFranchiseImages );
    $( '#franchiseRowCurrent' ).text( CurrentFranchiseImage );
    
    // Update function to display current image position
    function rotatorUpdate() {
      if( CurrentFranchiseImage >= TotalFranchiseImages ) {
        CurrentFranchiseImage = 1;
      } else {
        CurrentFranchiseImage++;
      }
      
      $( '#franchiseRowCurrent' ).text( CurrentFranchiseImage );  
    }
    
    // Interval loop that goes along with slideshow autoplay.
    // NOTE: Time needs to be the same as the autoplay-interval value in the DOM
    var autoplayFranchiseImages = setInterval( function() { rotatorUpdate(); }, 6000 );
    

    // Run update function and stop looping interval on clicking the next button
    $( '.franchise-row-nav-container a[uk-slideshow-item="next"]' ).on( 'click', function() {
      rotatorUpdate();
      
      clearInterval( autoplayFranchiseImages );
    }
      );

    // Click on the image stop auto-rotation, so clicking it should stop the pagination
    $( '#franchiseRowImages' ).on( 'click', function() {
      clearInterval( autoplayFranchiseImages );
    } );
    
    $( '.franchise-row-nav-container a[uk-slideshow-item="previous"]' ).on( 'click', function() {   
      if( CurrentFranchiseImage <= 1 ) {
        CurrentFranchiseImage = TotalFranchiseImages;
      } else {
        CurrentFranchiseImage--;
      }
      
      $( '#franchiseRowCurrent' ).text( CurrentFranchiseImage );
      
      clearInterval( autoplayFranchiseImages );
    } );
    
    
    //  Update and display current and total number of testimonial list items
    var TotalTestimonials  = $( '.flannel-testimonial-container li' ).length;
    var CurrentTestimonial = 1;
    
    $( '#testimonialTotal' ).text( TotalTestimonials );
    $( '#testimonialCurrent' ).text( CurrentTestimonial );
    
    $( '.flannel-testimonial-container a[uk-slideshow-item="next"]' ).on( 'click', function() {   
      if( CurrentTestimonial >= TotalTestimonials ) {
        CurrentTestimonial = 1;
      } else {
        CurrentTestimonial++;
      }
      
      $( '#testimonialCurrent' ).text( CurrentTestimonial );  
    } );
    $( '.flannel-testimonial-container a[uk-slideshow-item="previous"]' ).on( 'click', function() {   
      if( CurrentTestimonial <= 1 ) {
        CurrentTestimonial = TotalTestimonials;
      } else {
        CurrentTestimonial--;
      }
      
      $( '#testimonialCurrent' ).text( CurrentTestimonial );  
    } );
    
    
    // Update and display current and total number of promo text list items
    var TotalPromoText   = $( '.promo-text-container li' ).length;
    var CurrentPromoText = 1;
    
    $( '#promoTextTotal' ).text( TotalPromoText );
    $( '#promoTextCurrent' ).text( CurrentPromoText );
    
    $( '.promo-text-container a[uk-slideshow-item="next"]' ).on( 'click', function() {   
      if( CurrentPromoText >= TotalPromoText ) {
        CurrentPromoText = 1;
      } else {
        CurrentPromoText++;
      }
      
      $( '#promoTextCurrent' ).text( CurrentPromoText );  
    } );
    $( '.promo-text-container a[uk-slideshow-item="previous"]' ).on( 'click', function() {   
      if( CurrentPromoText <= 1 ) {
        CurrentPromoText = TotalPromoText;
      } else {
        CurrentPromoText--;
      }
      
      $( '#promoTextCurrent' ).text( CurrentPromoText );  
    } );
    
    
    /**
     * Check if page has an application form, if the submitted form was
     * successful or contains errors, scroll to the top of the form
     */
    if( $( '.form-container' ).length > 0 ) {
      if( $( '.gform_confirmation_message' ).length > 0 || $( '.gform_validation_error' ).length > 0 ) {
        $( 'html, body' ).animate( { scrollTop: $( '.form-container' ).offset().top - 30 }, 300 );
      }
    }    
    if( $( '.footer-form-container' ).length > 0 ) {
      if( $( '.footer-form-container' ).find( '.gform_confirmation_message' ).length !== 0 ||
          $( '.footer-form-container' ).find( '.gform_validation_error' ).length !== 0 ) {
        $( 'html, body' ).animate( { scrollTop: $( '.footer-form-container' ).offset().top - 0 }, 300 );
      }
    }    
    if( $( '.league-form-container' ).length > 0 ) {
      if( $( '.league-form-container' ).find( '.gform_confirmation_message' ).length !== 0 ||
          $( '.league-form-container' ).find( '.gform_validation_error' ).length !== 0 ) {
        $( 'html, body' ).animate( { scrollTop: $( '.league-form-container' ).offset().top - 30 }, 300 );
      }
    }    
    if( $( '.franchise-form-container' ).length > 0 ) {
      if( $( '.franchise-form-container' ).find( '.gform_confirmation_message' ).length !== 0 ||
          $( '.franchise-form-container' ).find( '.gform_validation_error' ).length !== 0 ) {
        $( 'html, body' ).animate( { scrollTop: $( '.franchise-form-container' ).offset().top - 0 }, 300 );
      }
    }
    
    // Playback video on hover for devices larger than 960px
    function hoverVideo() {  
      $( 'video', this ).get(0).play(); 
    }
    
    function hideVideo() {
      $( 'video', this ).get(0).pause(); 
    }
    
    if( width >= 960 ) {
      $( ".circle-video-container, .flannel-video-thumbnail" ).hover( hoverVideo, hideVideo );
    }
    
    
    // Insert label into input field, float label when field is selected
    function floatLabel( inputType ) {
      $( inputType ).each( function() {
        $( this ).focus( function() {
          $( this ).closest( 'li' ).addClass( 'has-focus-or-value' );
          $( this ).addClass( 'this-input-has-been-clicked' );
        } );
        
        // Checks if value is not empty, adds class to prevent label value overlap
        if( $( this ).val() !== '' ) {
          $( this ).closest( 'li' ).addClass( 'has-focus-or-value' );
          $( this ).addClass( 'this-input-has-been-clicked' );
        }

        $( this ).blur( function() {
          if( $( this ).val() === '' || $( this ).val() === 'blank' ) {
            $( this ).closest( 'li' ).removeClass( 'has-focus-or-value' );
            $( this ).removeClass( 'this-input-has-been-clicked' );
          }
        } );
      } );
    }

    floatLabel( '.special-label input' );
    floatLabel( '.special-label select' );
    
    
    // Show/hide chat-app button when window scrolls 160px
    $( window ).scroll( function() {
      if( $( this ).scrollTop() > 160 ) {
        if( !$( '.chat-app-button' ).hasClass( 'show' ) ) { 
          $( '.chat-app-button' ).addClass( 'show' );
        }
      }
      
      else {
        if( $( '.chat-app-button' ).hasClass( 'show' ) ) {
          $( '.chat-app-button' ).removeClass( 'show' );
        }
      }
    } );
    
    
    
//    UIkit.on('beforeready.uk.dom', function () {
      if (width < 767) {
        $( document ).find('[data-uk-scrollspy]').removeAttr('data-uk-scrollspy');
      }
//    });
    
    
  } );
} )( jQuery );
