var jQuery;

( function($) {
  $( '.mobile-nav-controller' ).click( function() {
    $( '.mobile-nav-controller' ).toggleClass( 'open' );  // Animate mobile hambuger menu icon
    
    $( '#flannelSiteMenu' ).toggleClass( 'expand' );  // Slide mobile menu down

    $( 'html' ).toggleClass( 'uk-overflow-hidden' );
    
//    $( '.modal-background' ).toggleClass( 'on' );     // Fade background to black
    
//    $( '.mobile-header' ).toggleClass( 'open' );      // Adds scroll feature to menu
    } );
  
  
  // Mobile hamburger menu for franchise theme
  $( '.franchise-nav-controller' ).click( function() {
    $( '.franchise-nav-controller' ).toggleClass( 'open' );  // Animate mobile hambuger menu icon
    
    $( '#franchiseSiteMenu' ).toggleClass( 'expand' );  // Slide mobile menu down

    $( 'html' ).toggleClass( 'uk-overflow-hidden' );
    
//    $( '.modal-background' ).toggleClass( 'on' );     // Fade background to black
    
//    $( '.mobile-header' ).toggleClass( 'open' );      // Adds scroll feature to menu
    } );
  
  
  
  
      // Show angle-down arrow for links with children
  $( '.menu-item-has-children.drop-title > a' ).append( '<i class="far fa-chevron-down"></i>' );
  
  $( '.nav-primary a[target="_blank"]' ).append( '<i class="fas fa-external-link-alt" style="padding-left: 5px; font-size: 14px;"></i>' );  
  
  $( '.main a[target="_blank"]' ).append( '<i class="fas fa-external-link-alt" style="padding-left: 5px; font-size: 14px;"></i>' );  
   
  $( '.flannel-extra-nav a[target="_blank"]' ).append( '<i class="fas fa-external-link-alt" style="padding-left: 5px; font-size: 14px;"></i>' );  
  $( '.flannel-legal-nav a[target="_blank"]' ).append( '<i class="fas fa-external-link-alt" style="padding-left: 5px; font-size: 14px;"></i>' );  
  
  // remove double instant of icon
  $( '.main .nav-primary a[target="_blank"] i').remove();
  $( '.main .nav-primary a[target="_blank"]').append( '<i class="fas fa-external-link-alt" style="padding-left: 5px; font-size: 14px;"></i>' );
  
    
    
    // Hide sub-categories on mobile menu
    $( '#mobileNav li.drop-title > ul' ).css( 'display', 'none' );
  
  
  
      // Apply overview link to top of mobile sub menu
    $( '#mobileNav li.drop-title > a' ).one( 'click', function() { 
      var target  = $( this );
      var value   = target.attr( 'href' );
      var element = target.parent( 'li' );
      
      if( value !== '#' ) {
        target.removeAttr( 'href' );
        
        if( document.documentElement.lang.includes( 'fr' ) ) {
          element.children( 'ul' ).first( 'li' ).prepend( '<li><a href="' + value + '">Aperçu</a></li>' );
        }

        else {
          element.children( 'ul' ).first( 'li' ).prepend( '<li><a href="' + value + '">Overview</a></li>' );
        }
      }
    } );
  
      // Mobile sub menu conditions
    $( '#mobileNav li.drop-title > a' ).on( 'click', function(event) {
      var target  = $( this );
      var element = target.parent( 'li' );
      
      event.preventDefault();
      
      // Reset all angle-down arrows
      $( '#mobileNav li.drop-title > a i' ).css( 'transform', 'scale( -1, 1 )' );
  
      // Close sub menu
      if( element.hasClass( 'open' ) ) {
		element.removeClass( 'open' );
		element.find( 'li' ).removeClass( 'open' );
		element.find( 'ul' ).slideUp();
      }
	
      // Open sub menu
      else {
        element.addClass( 'open' );
        element.children( 'ul' ).slideDown();
        element.siblings( 'li' ).children( 'ul' ).slideUp();
        element.siblings( 'li' ).removeClass( 'open' );
        element.siblings( 'li' ).find( 'li' ).removeClass( 'open' );
        element.siblings( 'li' ).find( 'ul' ).slideUp();
        target.find( 'i' ).css( 'transform', 'scale( 1, -1 )' );
      }
	} );  
  
  
  
  
  
  
  
  
} )( jQuery );
